@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat.ttf) format("truetype");
}

body {
  font-family: Montserrat !important;
  background-color: #f5f5f5;
  color: #282827;
}

.ant-menu-item {
  margin-bottom: 10px !important;
  height: 46px !important;
}

.ant-menu-item:hover {
  background-color: #E3B027 !important;
  color: white !important;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  background-color: white !important;
  color: #b5b7c0;
  text-align: center;
  font-size: 16px;
}
.table-layout {
  border-radius: 30px;
  overflow: hidden;
  /* padding: 50px; */
}

.ant-input-search-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2b024;
}

.ant-spin-dot-item {
  background-color: #fff !important;
}

.ant-picker-ok button {
  background-color: #e2b024 !important ;
  color: #fff !important;
  border-color: #e2b024;
}

.ant-menu-item-selected {
  border-right: 4px solid;
}

.ant-pagination-total-text{
  color: #B5B7C0;
}
.ant-pagination-item {
  background-color: #F5F5F5 !important;
  border: 1px solid #EEEEEE !important;
}

.ant-pagination-item a {
  color: #404B52 !important;
  font-weight: 500;
  
}

.ant-pagination-item-link {
  background-color: #F5F5F5 !important;
  border: 1px solid #EEEEEE !important;
  color: #404B52 !important;
}

.ant-pagination-item-active a {
  background-color: #e2b024;
  color: white !important;
  border-radius: 2px !important;
}

.ant-tabs-tab{
  color: #42572A !important;
  /* font-weight: 300 !important; */
  font-weight: 500 !important;
}

.ant-tabs-tab-active .ant-tabs-tab-btn {

  color: #42572A !important;
  font-weight: 700 !important;
  border: none !important;
}


.ant-tabs-ink-bar {
  background-color: #E3B027 !important; 
  border-radius: 3px !important;
  height: 4px !important;

}




:where(.css-dev-only-do-not-override-u8obfl).ant-modal .ant-modal-content {
  box-shadow: none !important;
}

/* :where(.css-dev-only-do-not-override-u8obfl).ant-select .ant-select-arrow {
  top: 65% !important;
} */

.ant-menu-item-selected{
  background-color: #E3B027 !important;
  border: none;

}

.ant-upload{
  background: #FBFBFB;
  height: 300px !important;
  border: #E3B027;
  border-style: dashed;
  border-width: 3px;
  border-radius: 12px;
}

.ant-upload-drag{
  border-style: none !important;
  
}
